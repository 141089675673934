import { useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import mailIcon from "../../assets/img/mail.svg";
import UserTerms from "./UserTerms";

interface RegisterFormProps {
    registerCallback: CallableFunction;
}

export default function RegisterForm(props: RegisterFormProps) {
    const [working, setWorking] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setWorking(true);
        debugger;
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: event.target.email.value,
                privacy_policy: event.target.privacy_policy.checked
            })
        };
        fetch('https://srv.taskgen.eu/v1/user/register', requestOptionsPost)
            .then(response => response.json())
            .then(data => {
                setWorking(false);
                if (data.status == 'ok') {
                    alert('Check your inbox and follow the instructions to finish your registration.');
                } else {
                    alert('There was a problem with the registration. It is possible that a user with the same Email is already registered. Please try again later or write to us at info@taskgen.eu.');
                }
                props.registerCallback();
            });
    }

    const handleShowTerms = () => {
        setShowTerms(true);
    }

    const handleHideTerms = () => {
        setShowTerms(false);
    }

    return(
        <Form className="form-register" onSubmit={ handleSubmit }>
            <Alert variant={ 'info' }>
                { 'Write your mail address and we\'ll send you an email with instructions to finish your registration.'}
            </Alert>
            <div className="field email">
                <img src={ mailIcon } alt=""/>
                <input type="email" name="email" placeholder="Email Address" disabled={ working } required />
                <div className="terms-container">
                    <div className="accept-field">
                        <input type="checkbox" required name="privacy_policy" /> { 'By clicking this box, I state that I have read and understood the ' }
                        <a onClick={ handleShowTerms }>{ 'terms and conditions' }</a>.
                    </div>
                </div>
            </div>
            <div className="actions">
                { !working && 
                    <input className="submit btn btn-primary" type="submit" value='Register' disabled={ working } />
                }
                { working && 
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </div>
            { showTerms && <UserTerms closeCallback={ handleHideTerms } /> }
        </Form>         
    );
}