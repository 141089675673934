import { useEffect, useState } from "react";
import { PosTask, PreTask, SubTask, Task, aimsOfTask, proficiencyLevelsOfTask } from "../interfaces/Task";
import { Link, useParams } from "react-router-dom";
import PreviewHeader from "../header/PreviewHeader";
import { Col, Container, Row } from "react-bootstrap";
import SiteSubtask from "./SubTask/SiteSubtask";
import SitePreTask from "./PreTaskTypes/SitePreTask";
import SitePosTask from "./PosTaskTypes/SitePosTask";
import { useNavigate } from "react-router-dom";

export default function TaskSite() {
    const navigate = useNavigate();
    const params= useParams();
    const task_id: string = params.id === undefined ? '' : params.id;
    const mode: string = params.mode === undefined ? '' : params.mode;

    const [task, setTask] = useState<Task>({
        _id: '',
        name: '',
        pretasks: [],
        tasks: [],
        postasks: [],
        updated: new Date(),
        userId: '',
        active: false,
        authorship: '',
        aims: [],
        aim_other: '',
        specific_aim: '',
        proficiency_level: [],
        original_source: '',
        proficiency_level_comments: '',
        sessions: [],
        clonned: false,
        original_task: ''
    });

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + params.id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.aims === undefined) {
                    data.aims = [];
                }
                if (data.proficiency_level === undefined) {
                    data.proficiency_level = [];
                }
                setTask(data);
            });
    }, []);

    return (
        <div className="page">
            <div className="task-site">
                <PreviewHeader />
                <Container>
                    <Row>
                        <Col>
                            <Link className="back" to={ '/edit-task/' + task_id }>{ 'back to the task' }</Link>
                            <h1>
                                { mode == 's' && 'Shared with students' }
                                { mode == 't' && 'Shared with teachers' }
                            </h1>
                            <div className="task-header">
                                <div className="inner">
                                    <div className="title">
                                        <span className="label">{ 'Title' }:</span> { task.name }
                                    </div>
                                    <div className="authorship">
                                        <span className="label">{ 'Authorship' }:</span> { task.authorship }
                                    </div>
                                    <div className="proficiency-level">
                                        <span className="label">{ 'Student\'s proficiency level' }:</span>
                                        { proficiencyLevelsOfTask.map((l: any) => {
                                            const index: number = task.proficiency_level.indexOf(l.value);
                                            if (index !== -1) {
                                                return (<span className="value">{ l.description }</span>);
                                            }
                                        })}
                                    </div>
                                    <div className="aims">
                                        <span className="label">{ 'Aims' }:</span>
                                        { aimsOfTask.map((a: any) => {
                                            const index: number = task.aims.indexOf(a.value);
                                            if (index !== -1) {
                                                return (<span className="value">{ a.description }</span>);
                                            }
                                        })}
                                        { task.specific_aim &&
                                            <span className="value">{ task.specific_aim }</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="pretasks">
                                <h2>{ 'Pre-Task' }</h2>
                                { task.pretasks.filter((t: PreTask) => (mode == 's' && t.students) || (mode == 't' && t.teachers)).length == 0 && 
                                    <div className="message error">
                                        { 'There are no pre-tasks'}
                                    </div>
                                }
                                { task_id !== undefined && task.pretasks.length > 0 &&
                                    <div className="pretasks-list">
                                        { task.pretasks.map((t: PreTask) => {
                                            if ((mode == 's' && t.students) || (mode == 't' && t.teachers)) {
                                                return (<SitePreTask pretask={ t } key={ 'pretask-' + t._id } mode={ mode } />)
                                            }
                                        })}
                                    </div>
                                }
                            </div>
                            <div className="tasks">
                                <h2>{ 'Tasks' }</h2>
                                { task.tasks.filter((t: SubTask) => (mode == 's' && t.students_session) || (mode == 't' && t.teachers_session)).length == 0 && 
                                    <div className="message error">
                                        { 'There are no tasks'}
                                    </div>
                                }
                                { task_id !== undefined && task.tasks.length > 0 &&
                                    <div className="tasks-list">
                                        { task.tasks.map((t: SubTask) => {
                                            if ((mode == 's' && t.students_session) || (mode == 't' && t.teachers_session)) {
                                                return (<SiteSubtask subtask={ t } key={ 'subtask-' + t._id } mode={ mode } />)
                                            }
                                        })}
                                    </div>
                                }
                            </div>
                            <div className="postasks">
                                <h2>{ 'Post-Task' }</h2>
                                { task.postasks.filter((t: PosTask) => (mode == 's' && t.students) || (mode == 't' && t.teachers)).length == 0 && 
                                    <div className="message error">
                                        { 'There are no post-tasks'}
                                    </div>
                                }
                                { task_id !== undefined && task.pretasks.length > 0 &&
                                    <div className="postasks-list">
                                        { task.postasks.map((t: PosTask) => {
                                            if ((mode == 's' && t.students) || (mode == 't' && t.teachers)) {
                                                return (<SitePosTask postask={ t } key={ 'postask-' + t._id } mode={ mode } />)
                                            }
                                        })}
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}