import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { Alert } from 'react-bootstrap';
import AnalyzedText from './AnalyzedText';
import { useNavigate } from "react-router-dom";
import { debug } from 'console';

export interface CustomEditorProps {
    id: string;
    label: string;
    data: string;
    change: CallableFunction;
    npl: boolean;
}

export default function CustomEditor(props: CustomEditorProps) {
    const navigate = useNavigate();
    const [processingText, setProcessingText] = useState(false);
    const [text, setText] = useState(props.data);
    const [messageDuration, setMessageDuration] = useState('');
    const [analyzedText, setAnalyzedText] = useState<any>(null);
    const [showAnalyze, setShowAnalyze] = useState(false);

    const handleTextAnalyzed = (data: any) => {
        setShowAnalyze(false);
        setAnalyzedText(data);
        setShowAnalyze(true);
    }

    const processText = (e: any) => {
        e.preventDefault();
        setShowAnalyze(false);
        if (text.length > 0) {
            setProcessingText(true);

            setMessageDuration('');

            let _data = {
                uid: Math.random().toString(36).slice(-5),
                text: text.replaceAll('<p>', '').replaceAll('<br>', '\n\n').replaceAll('</p>', '\n\n\n\n').replace(/<[^>]*>?/gm, '')
            }
             debugger;   
            const startTimestamp = Date.now();
            fetch('https://srv.taskgen.eu/v1/nlp/run', {
                method: "POST",
                body: JSON.stringify(_data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            }) 
            .then(json => {
                const execTime = Date.now() - startTimestamp;
                const totalSeconds = Math.floor(execTime / 1000);
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                const messageArray: string[] = [];
                if (hours > 0) {
                    messageArray.push(hours == 1 ? hours + ' hour' : hours + ' hours');
                }
                if (minutes > 0) {
                    messageArray.push(minutes == 1 ? minutes + ' minute' : minutes + ' minutes');
                }
                if (seconds > 0) {
                    messageArray.push(minutes == 1 ? seconds + ' second' : seconds + ' seconds');
                }
                setMessageDuration(messageArray.join(' '));
        
                setProcessingText(false);
                handleTextAnalyzed(json);
            }).catch(err => console.log(err));
        }
    }

    return (
        <div className={ 'field field-custom-editor ' + props.id }>
            <div className="label">{ props.label }</div>
            <div className="editor-container">
                <CKEditor
                    editor={ ClassicEditor }
                    data= { props.data }
                    onChange={ ( event, editor: any ) => {
                        const data = editor.getData();
                        setText(data);
                        props.change(data);
                    } }
                    config = { {
                        toolbar: {
                            items: [ 'heading', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|', 
                                'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|',
                                'bulletedList', 'numberedList', 'indent', 'outdent', 'alignment', 'horizontalLine', '|',
                                'link', 'uploadImage', 'mediaEmbed', '|',
                                'insertTable', '|',
                                'PasteFromOffice', 'removeFormat'
                                ],
                            shouldNotGroupWhenFull: true,
                        }
                    }}
                />
            </div>
            { props.npl && text.length > 0 &&
                <div className="text-processing-container">
                    <Alert variant='warning'>
                        <Alert.Heading><strong>{ 'Important' }</strong></Alert.Heading>
                        <div>
                            { 'The tool is still under construction, but give it a try! We\'re working on it.' }
                        </div>
                    </Alert>
                    <button className={ 'btn btn-primary process-text' + (processingText ? ' processing' : '') }
                        onClick={ processText }>
                        { 'Analyze the text' }
                    </button>
                    { messageDuration && 
                        <span>{ 'Text analyzed in' } <strong>{ messageDuration }</strong></span>
                    }
                </div>
            }
            { showAnalyze && <AnalyzedText analyzedText={ analyzedText } /> }
        </div>
    );
}