import Moment from 'moment';
import { Task } from "../interfaces/Task";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DeleteTask from './DeleteTask';
import ShareTask from './ShareTask';

interface TaskItemProps {
    task: Task;
    deleteCallback: CallableFunction;
    duplicateCallback: CallableFunction;
}

export default function TaskItem(props: TaskItemProps) {
    const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
    const [showShareConfirmation, setShareConfirmation] = useState(false);

    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('/edit-task/' + props.task._id);
    }

    const handleDelete = () => {
        setDeleteConfirmation(true);
    }

    const handleDeleteConfirm = () =>{
        setDeleteConfirmation(false);
        props.deleteCallback(props.task._id);
    }

    const handleDeleteClose = () => {
        setDeleteConfirmation(false);
    }

    const handleShare = () => {
        setShareConfirmation(true);
    }

    const handleShareConfirm = () =>{
        setShareConfirmation(false);
    }

    const handleShareClose = () => {
        setShareConfirmation(false);
    }


    const handleDuplicate = () => {
        const newTask = Object.assign({}, props.task);
        newTask._id = '';
        newTask.name = props.task.name + ' (copy)';
        newTask.clonned = true;
        newTask.original_source = 'own';
        newTask.original_task = props.task._id;

        // Create a new task
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                props.duplicateCallback(data);
            });
    }

    return(
        <div className="task-item teaser" key={ 'task-' + props.task._id }>
            <div className="title">
                { props.task.name }
            </div>
            <div className="meta">
                <div className="changed">
                    { 'Last updated' }: <span>{ Moment(props.task.updated).format('DD/MM/YYYY') }</span>
                </div>
                <div className="actions">
                    <button className="no-decoration edit" title="Edit" onClick={ handleEdit }></button>
                    <button className="no-decoration share" title="Share" onClick={ handleShare }></button>
                    <button className="no-decoration duplicate" title="Duplicate" onClick={ handleDuplicate }></button>
                    <button className="no-decoration delete" title="Delete" onClick={ handleDelete }></button>
                </div>
            </div>
            { showDeleteConfirmation && <DeleteTask task={ props.task } deleteCallback={ handleDeleteConfirm } closeCallback={ handleDeleteClose }/> }
            { showShareConfirmation && <ShareTask task={ props.task } shareCallback={ handleShareConfirm } closeCallback={ handleShareClose }/> }
        </div>
    );
}