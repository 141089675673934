import { Button, Form } from "react-bootstrap";
import { Task } from "../interfaces/Task";
import { useEffect, useState } from "react";
import { User, emptyUser } from "../interfaces/User";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

interface ShareTaskProps {
    task: Task,
    shareCallback: CallableFunction;
    closeCallback: CallableFunction;
}


export default function ShareTask(props: ShareTaskProps) {
    const navigate = useNavigate();
    const [user, setUser] = useState<User>(emptyUser);
    const [orgShare, setOrgShare] = useState(false);
    const [usersFound, setUsersFound] = useState<any[]>([]);
    const [usersSelected, setUsersSelected] = useState<any[]>([]);

    const handleShare = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                task: props.task._id,
                users: usersSelected
            })
        };
        fetch('https://srv.taskgen.eu/v1/task/share/', requestOptions)
            .then(response => {
                props.shareCallback();
            })
            .catch(error => {
                navigate('/');
            });
    }

    const handleClose = () => {
        props.closeCallback();
    }

    const handleShareWithOrgChange = (event: any) => {
        setOrgShare(event.target.checked);
        setUsersFound([]);
    }

    const handleUsersSearch = (event: any) => {
        if (event.target.value.length < 3) {
            setUsersFound([]);
        } else {
            let reqUrl = 'https://srv.taskgen.eu/v1/users/';
            let queryParams: string[] = [];
            if (orgShare) {
               queryParams.push('org=' + user.org._id);
            }
            queryParams.push('search=' + event.target.value);
            const requestOptionsPost = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            reqUrl += '?' + queryParams.join('&');
            fetch(reqUrl, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    const newUsersFound = data.map((u: any) => {
                        let nameParts: string[] = [];
                        if (u.profile !== undefined) {
                            if (u.profile.firstname) {
                                nameParts.push(u.profile.firstname);
                            }
                            if (u.profile.lastname) {
                                nameParts.push(u.profile.lastname);
                            }
                        }
                        return {
                            id: u._id,
                            label: u.email + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '')
                        }
                    });
                    setUsersFound(newUsersFound);
                });
        }
    }

    const handleSelectUser = (event: any) => {
        const id = event.target.id.replace('userFound-', '');
        const selectedUser = usersFound.find((u: any) => u.id == id);
        const newUsersSelected = [
            ...usersSelected,
            selectedUser
        ];
        setUsersSelected(newUsersSelected);
    }

    const handleUnselectUser = (event: any) => {
        const id = event.target.id.replace('userShared-', '');
        const newUsersSelected = usersSelected.filter((u: any) => u.id != id);
        setUsersSelected(newUsersSelected);
    }

    useEffect(() => {
        const userId = localStorage.getItem('userId') ?? null;

        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setUser(data);
            });

        fetch('https://srv.taskgen.eu/v1/task/shares/' + props.task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newUsersSelected = data.map((u: any) => {
                    let nameParts: string[] = [];
                    if (u.user.profile !== undefined) {
                        if (u.user.profile.firstname) {
                            nameParts.push(u.user.profile.firstname);
                        }
                        if (u.user.profile.lastname) {
                            nameParts.push(u.user.profile.lastname);
                        }
                    }
                    return {
                        id: u.user._id,
                        label: u.user.email + (nameParts.length > 0 ? ' (' + nameParts.join(' ') +  ')' : '')
                    }
                });
                setUsersSelected(newUsersSelected);
            });    
    }, []);

    return (
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <div className="share-task-container">
                        <div className="inner">
                            <h2>{ 'Share task' }</h2>
                            <p>
                                You can share the task <strong>{ props.task.name }</strong> with other people.
                            </p>
                            { user.org != null &&
                                <Form.Check
                                    type="switch"
                                    id="share-with-org"
                                    label={ 'Shared with someone at ' + user.org.name }
                                    onChange={ handleShareWithOrgChange }
                                    defaultChecked = { false }
                                />
                            }
                            <div className="share-options-container">
                                <div className="search">
                                    <Form.Control type="text" placeholder="Search users..." onChange={ handleUsersSearch } />
                                </div>
                                { usersFound.length > 0 && 
                                    <div className="users">
                                        <div className="found">
                                            <div className="label">
                                            { parse('Select the users with whom you want to <strong>share the task</strong>:') }
                                            </div>
                                            <ul>
                                                { usersFound.filter((f: any) => usersSelected.findIndex((s: any) => s.id == f.id) === -1).map((u: any) => {
                                                    return <li key={ `userFound-${u.id}` }>
                                                        <span>
                                                            { u.label } <a className="select-user" onClick={ handleSelectUser } id={ `userFound-${u.id}`}></a>
                                                        </span>
                                                    </li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                { usersSelected.length > 0 &&
                                    <div className="shared">
                                        <div className="label">
                                            { parse('Task is shared with the following users:') }
                                        </div>
                                        <ul>
                                            { usersSelected.map((u: any) => {
                                                return <li key={ `userShared-${u.id}` }>
                                                    <span>
                                                        { u.label } <a className="unselect-user" onClick={ handleUnselectUser } id={ `userShared-${u.id}`}></a>
                                                    </span>
                                                </li>; 
                                            })}
                                        </ul>
                                    </div>
                            }
                            </div>
                            <div className="actions">
                                <Button className="btn btn-primary" onClick={ handleShare }>{ 'Share' }</Button>
                                <Button className="btn btn-warning" onClick={ handleClose }>{ 'Cancel' }</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}