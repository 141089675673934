import { useEffect, useState } from "react";
import { PosTask, PreTask, SubTask, Task, TaskSession, aimsOfTask, proficiencyLevelsOfTask } from "../interfaces/Task";
import { Link, useParams } from "react-router-dom";
import PreviewHeader from "../header/PreviewHeader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PreviewSubTask from "./SubTask/PreviewSubTask";
import PreviewPreTask from "./PreTaskTypes/PreviewPreTask";
import PreviewPosTask from "./PosTaskTypes/PreviewPosTask";
import Moment from 'moment';
import { useNavigate } from "react-router-dom";

export default function PreviewTask() {
    const navigate = useNavigate();
    const params= useParams();
    const task_id: string = params.id === undefined ? '' : params.id;

    const [selectedSession, setSelectedSession] = useState('');
    const [task, setTask] = useState<Task>({
        _id: '',
        name: '',
        pretasks: [],
        tasks: [],
        postasks: [],
        updated: new Date(),
        userId: '',
        active: false,
        authorship: '',
        aims: [],
        aim_other: '',
        specific_aim: '',
        original_source: '',
        proficiency_level: [],
        proficiency_level_comments: '',
        sessions: [],
        clonned: false,
        original_task: ''
    });

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + params.id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.aims === undefined) {
                    data.aims = [];
                }
                if (data.proficiency_level === undefined) {
                    data.proficiency_level = [];
                }
                setTask(data);
            });
    }, []);

    const handleNewSession = (event: any) => {
        event.preventDefault();
        const newSessions = task.sessions.map((s: TaskSession) => { return s; });
        const newSession: TaskSession = {
            _id: '',
            name: event.target.name.value,
            datetime: event.target.datetime.value
        }
        newSessions.push(newSession);
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newSessions)
        };
        fetch('https://srv.taskgen.eu/v1/task/sessions/' + task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTask(data);
            });
    }

    const handleEditSession = (event: any) => {
        event.preventDefault();
        setSelectedSession(event.target.dataset.id);
    }  

    const handleDeleteSession = (event: any) => {
        event.preventDefault();
        const newSessions = task.sessions.filter((s: TaskSession) => event.target.dataset.id != s._id);
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newSessions)
        };
        fetch('https://srv.taskgen.eu/v1/task/sessions/' + task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTask(data);
            });
    }

    const handleUpdateSession = (event: any) => {
        event.preventDefault();
        const newSessions = task.sessions.map((s: TaskSession) => {
            const curSession = Object.assign({}, s);
            if (s._id == selectedSession) {
                curSession.name = event.target.name.value;
                curSession.datetime = event.target.datetime.value;
            }
            return curSession;
        });
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newSessions)
        };
        fetch('https://srv.taskgen.eu/v1/task/sessions/' + task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTask(data);
            });
        setSelectedSession('');
    }

    const cancelUpdateSession = (event: any) => {
        event.preventDefault();
        setSelectedSession('');
    }

    const handleExportTeachersVersion = (event: any) => {
        event.preventDefault();
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/export/t', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                window.open(data.zip, '_blank');
            });
    }

    const handleExportStudentsVersion = (event: any) => {
        event.preventDefault();
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/export/s', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                window.open(data.zip, '_blank');
            });
    }

    return (
        <div className="page">
            <div className="preview-task">
                <PreviewHeader />
                <Container>
                    <Row>
                        <Col>
                            <Link className="back" to={ '/edit-task/' + task_id }>{ 'back to the task' }</Link>
                            <h1 className="task-title">
                                { 'My design' }
                                <div className="export-actions">
                                    <a onClick={ handleExportTeachersVersion } className="btn btn-primary">
                                        { 'Export Teacher\'s version' }
                                    </a>
                                    <a onClick={ handleExportStudentsVersion } className="btn btn-primary">
                                        { 'Export Sutudent\'s version' }
                                    </a>
                                </div>
                            </h1>
                            <div className="task-header">
                                <div className="inner">
                                    <div className="title">
                                        <span className="label">{ 'Title' }:</span> { task.name }
                                    </div>
                                    <div className="authorship">
                                        <span className="label">{ 'Authorship' }:</span> { task.authorship }
                                    </div>
                                    <div className="proficiency-level">
                                        <span className="label">{ 'Student\'s proficiency level' }:</span>
                                        { proficiencyLevelsOfTask.map((l: any) => {
                                            const index: number = task.proficiency_level.indexOf(l.value);
                                            if (index !== -1) {
                                                return (<span className="value">{ l.description }</span>);
                                            }
                                        })}
                                    </div>
                                    <div className="aims">
                                        <span className="label">{ 'Aims' }:</span>
                                        { aimsOfTask.map((a: any) => {
                                            const index: number = task.aims.indexOf(a.value);
                                            if (index !== -1) {
                                                return (<span className="value">{ a.description }</span>);
                                            }
                                        })}
                                        { task.specific_aim &&
                                            <span className="value">{ task.specific_aim }</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="task-sessions">
                                <h4>{ 'Sessions' }</h4>
                                <div className="sessions-list">
                                    { task.sessions.length > 0 &&
                                        <ul>
                                            { task.sessions.sort((a: TaskSession, b: TaskSession) => parseInt(Moment(a.datetime).format('YYYYMMDD')) - parseInt(Moment(b.datetime).format('YYYYMMDD')))
                                                .map((s: TaskSession) => {
                                                    if (s._id == selectedSession) {
                                                        return <Form className="edit-session-form" onSubmit={ handleUpdateSession }>
                                                            <Form.Control type="hidden" name="id" value={ s._id} />
                                                            <Form.Group className="field field-name">
                                                                <Form.Control type="text" name="name" placeholder={ 'Session name' }
                                                                    required defaultValue={ s.name } />
                                                            </Form.Group>
                                                            <Form.Group className="field field-datetime">
                                                                <Form.Control type="date" name="datetime" required defaultValue={ Moment(s.datetime).format('YYYY-MM-DD') } />
                                                            </Form.Group>
                                                            <Form.Group className="actions">
                                                                <Button className="btn btn-primary" type="submit">
                                                                    { 'Update' }
                                                                </Button>
                                                                <Button className="btn btn-secondary" onClick={ cancelUpdateSession }>
                                                                    { 'Cancel' }
                                                                </Button>
                                                            </Form.Group>
                                                        </Form>;
                                                    } else {
                                                    return <li key={ 'session-' + s._id }>
                                                            <div className="name">{ s.name }</div>
                                                            <div className="date">({ Moment(s.datetime).format('DD/MM/YYYY') })</div>
                                                            <div className="actions">
                                                                <a className="edit-link" data-id={ s._id } onClick={ handleEditSession }></a>
                                                                <a className="delete-link" data-id={ s._id } onClick={ handleDeleteSession }></a>
                                                            </div>
                                                        </li>;
                                                    }
                                                })
                                            }
                                        </ul>
                                    }
                                    { selectedSession == '' &&
                                        <Form className="new-session-form" onSubmit={ handleNewSession }>
                                            <Form.Group className="field field-name">
                                                <Form.Control type="text" name="name" placeholder={ 'Session name' } required />
                                            </Form.Group>
                                            <Form.Group className="field field-datetime">
                                                <Form.Control type="date" name="datetime" required />
                                            </Form.Group>
                                            <Form.Group className="actions">
                                                <Button className="btn btn-primary" type="submit">
                                                    { 'Add session' }
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    }   
                                </div>
                            </div>
                            <div className="pretasks">
                                <h2>{ 'Pre-Task' }</h2>
                                { task.pretasks.length == 0 &&
                                    <div className="message error">
                                        { 'There are no pre-tasks defined'}
                                    </div>
                                }
                                { task_id !== undefined && task.pretasks.length > 0 &&
                                    <div className="pretasks-list">
                                        { task.pretasks.map((t: PreTask) => {
                                            return (<PreviewPreTask task_id={ task_id }
                                                        pretask={ t } key={ 'pretask-' + t._id }
                                                        sessions={ task.sessions } />)
                                        })}
                                    </div>
                                }
                            </div>
                            <div className="tasks">
                                <h2>{ 'Tasks' }</h2>
                                { task.tasks.length == 0 && 
                                    <div className="message error">
                                        { 'There are no tasks defined'}
                                    </div>
                                }
                                { task_id !== undefined && task.tasks.length > 0 &&
                                    <div className="tasks-list">
                                        { task.tasks.map((t: SubTask) => {
                                            return (<PreviewSubTask task_id={ task_id } 
                                                subtask={ t } key={ 'subtask-' + t._id }
                                                sessions={ task.sessions } />)
                                        })}
                                    </div>
                                }
                            </div>
                            <div className="postasks">
                                <h2>{ 'Post-Task' }</h2>
                                { task.postasks.length == 0 && 
                                    <div className="message error">
                                        { 'There are no post-tasks defined'}
                                    </div>
                                }
                                { task_id !== undefined && task.pretasks.length > 0 &&
                                    <div className="postasks-list">
                                        { task.postasks.map((t: PosTask) => {
                                            return (<PreviewPosTask task_id={ task_id } 
                                                postask={ t } key={ 'postask-' + t._id } sessions={ task.sessions } />)
                                        })}
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}